/*
 * SUPER SIMPLE STENIK RATING PLUGIN
 * VERSION: 1.1
 * DATE: 13-01-2015
 * @license Copyright(c) StenikGroup LTD. All rights reserved.
 * @author: Gani Georgiev, gani@stenik.bg
 * -------------------------------------------------------------------
 * METHODS:
 * -- init:          plugin init
 * -- destroy:       plugin inst clear
 * -- setValue:      set custom rating value ( $('input').stenikRating('setValue', 3); )
 * -- getValue:      get current rating value (var k = $('input').stenikRating('getValue'); )
 * -------------------------------------------------------------------
 * CALLBACKS:
 * -- onInit:        triggered on init
 * -- onSelect:      triggered on star click
 * -- onValueUpdate: triggered after custom value set (setValue method)
 */

;(function($) {
	var defaults = {
		stars: 5, // number of stars
		starWidth: 19, // sprite star width
		handles: true, // toggle stars handle
		onInit: function() {},
		onSelect: function(data) {},
		onValueUpdate: function() {}
	};

	var settings = {};

	// PRIVATE FUNCTIONS
	var updateValue = function(_this, _value, _settings) {
		var $this = jQuery(_this),
			$wrapper = $this.parents('.stenikRating').first(),
			$ratingSummaryBar = $wrapper.find('.ratingSummary .bar');
		
		$wrapper.removeClass('ratingSelected');

		$this.val(_value);

		$ratingSummaryBar.css({ width: (_value / _settings.stars * 100) + '%' });
	}

	// METHODS
	var methods = {
		init: function(options) {
			return this.each(function() {
				methods.destroy.call(this, options); // on reinit fix

				var $this = $(this);
				settings = $this.data('stenikRating');
				if(typeof(settings) == 'undefined') {
					settings = $.extend({}, defaults, options);
					$this.data('stenikRating', settings);
				} else {
					settings = $.extend({}, settings, options);
				}

				currentRating = parseFloat($this.attr('data-rating'));
				if(!currentRating) currentRating = 0;

				/*
					You could disable rating handles by 3 ways
					1. Set the settings prop 'handles: false'
					2. Add 'disabled' attr to the input
					3. Add "data-disabled="true"' to the input
			 	*/
				var disableHandles = false;
				if(!settings.handles || $this.attr('disabled') || $this.data('disabled') == true) {
					disableHandles = true;
				}

				/* GENERATE THE MARKUP
				 ****************************************************** */
				$this.wrap('<div class="stenikRating"></div>')
					  .wrap('<div class="inputWrapper"></div>');
	
				var $wrapper = $this.parents('.stenikRating').first();
				$wrapper.append('<div class="handlesWrapper"></div>');
				var $handlesWrapper = $wrapper.find('.handlesWrapper');
				
				if(!disableHandles) {
					// Append rating handles
					for(var i = 1; i <= settings.stars; i++) {
						$handlesWrapper.append('<span class="ratingHandle rating-'+i+'" data-rating="'+i+'"></span>');
					}
				} else {
					$wrapper.addClass('ratingDisabled');
				}
				// Append rating summary bar
				$wrapper.append('<div class="ratingSummary"><span class="bar"></span></div>');
				
				// Set rating summary wrapper width based on stars number
				var $ratingSummary = $wrapper.find('.ratingSummary');
				$ratingSummary.css({
					width: settings.stars * settings.starWidth
				});

				var $handles = $wrapper.find('.ratingHandle');
		 		updateValue($this, currentRating, settings);

				/* ACTIONS
			 	****************************************************** */
			 	// Hover
			 	$handles.hover(function() {
			 		$handles.removeClass('active');
			 		jQuery(this).addClass('active').prevAll($handles).addClass('active');
			 	}, function() {
					$handles.removeClass('active') // reset
							.filter('.selected').addClass('active')
						    .prevAll($handles).addClass('active');
			 	});

			 	// Click
				$handles.on('click', function() {
					$wrapper.addClass('ratingSelected');
					var ratingValue = jQuery(this).data('rating');
					settings.onSelect.call(this, ratingValue);
				});

				settings.onInit.call(this);
			});
		},
		destroy: function() {
			return $(this).each(function() {
				var $this = $(this),
					$wrapper = $this.parents('.stenikRating').first();
				
				$this.removeData('stenikRating');

				if($wrapper.length) {
					$this.insertBefore($wrapper);
					$wrapper.remove();
				}
			});
		},
		getValue: function() {
			var $this = jQuery(this);
			if($this.length > 1) {
				// return an array with the values
				var myValues = [];
				for(var i = 0; i < $this.length; i++)
					myValues.push($this.val());
				return myValues;
			} else {
				// return only one value
				return $this.val();
			}
			return 'undefined';
		},
		setValue: function(value) {
			return $(this).each(function() {
				var $this = jQuery(this);
				updateValue($this, value, settings);
				settings.onValueUpdate.call(this);
			});
		}
	};
	$.fn.stenikRating = function() {
		var method = arguments[0];
		if(methods[method]) {
			method = methods[method];
			arguments = Array.prototype.slice.call(arguments, 1);
		} else if( typeof(method) == 'object' || !method ) {
			method = methods.init;
		} else {
			$.error( 'Method ' +  method + ' does not exist on jQuery.stenikRating' );
			return this;
		}
		return method.apply(this, arguments);
	}
})(jQuery);