/* ARENA ANIMATED BACKGROUND
 * @version 3.0
 * @license Copyright(c) Stenik Group LTD. All rights reserved.
 * @author: gani@stenik.bg
 * -------------------------------------------------------------------
 * DEPENDENCIES:
 * --- PIXI JS
 * --- Modernizr
 * --- GSAP Lib pack (used as a time ticker)
 * -------------------------------------------------------------------
 */
import _stenikMain from './09_stenikMain'
import { gsap, Power1 } from "gsap";
const PIXI = require('./16_pixi.min.js');

;(function() {
	var isIE8 = document.getElementById('ie8_check'),
		windowWidth,
		windowHeight;
	var body = document.getElementsByTagName('body')[0];

	function hasClass(el, classToSearch) {
		return el.className && new RegExp("(^|\\s)" + classToSearch + "(\\s|$)").test(el.className);
	}
	function canvasSizeCheck() {
		windowWidth  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
		// if(windowWidth > 2000)
		// 	windowWidth = 2000;
	}


	var hasBranding =   hasClass(body, 'hasBranding');
	canvasSizeCheck();

	var popcornsList = [
		// left side
		[-150, -785, "popcorn3.png"],
		[-84,  -648, "popcorn2.png"],
		[38,   -676, "popcorn6.png"],
		[161,  -765, "popcorn7.png"],
		[285,  -576, "popcorn5.png"],
		[388,  -792, "popcorn3.png"],
		[494,  -713, "popcorn4.png"],
		[1028, -722, "popcorn1.png"],
		[758,  -654, "popcorn6.png"],
		[1338, -712, "popcorn8.png"],
		[1828, -762, "popcorn3.png"],
		[2078, -722, "popcorn1.png"],
		[2448, -742, "popcorn7.png"],
		[2718, -722, "popcorn3.png"],
		[2798, -722, "popcorn5.png"],
		[3038, -662, "popcorn3.png"],
		[3438, -672, "popcorn2.png"],

		// right side
		[-82,  700, "popcorn5.png"],
		[130,  703, "popcorn1.png"],
		[175,  583, "popcorn6.png"],
		[304,  536, "popcorn5.png"],
		[510,  583, "popcorn7.png"],
		[824,  629, "popcorn4.png"],
		[961,  747, "popcorn1.png"],
		[1210, 533, "popcorn3.png"],
		[1590, 633, "popcorn1.png"],
		[1837, 604, "popcorn3.png"],
		[2065, 643, "popcorn2.png"],
		[2304, 783, "popcorn1.png"],
		[2588, 653, "popcorn5.png"],
		[2408, 543, "popcorn2.png"]
	];
	var snacksList = [
		// left side
		[-80,  -770, "snack7.png"],
		[177,  -623, "snack2.png"],
		[651,  -587, "snack5.png"],
		[757,  -737, "snack3.png"],
		[1491, -670, "snack5.png"],
		[1781, -747, "snack2.png"],
		[1891, -576, "snack6.png"],
		[2401, -597, "snack7.png"],
		[3111, -707, "snack4.png"],

		// right side
		[40,   640, "snack5.png"],
		[287,  720, "snack6.png"],
		[704,  630, "snack4.png"],
		[832,  807, "snack3.png"],
		[1524, 600, "snack4.png"],
		[1814, 591, "snack4.png"],
		[1948, 800, "snack6.png"],
		[2402, 560, "snack3.png"],
		[2652, 722, "snack3.png"],
		[3082, 585, "snack2.png"]
	];
	var dotsList = [
		[-90, -710, "dots.png"],
		[60, 370, "dots.png"],
		[721, 490, "dots.png"],
		[640, -650, "dots.png"],
		[1430, -410, "dots.png"],
		[1520, 420, "dots.png"],
		[2000, -660, "dots.png"],
		[2100, 110, "dots.png"]
	];

	// Holders
	var popcorns = [];
	var snacks   = [];
	var dots     = [];
	// Create an array of assets to load, in the form of json files generated from TexturePacker
	var assetsToLoad = [item, "/images/frontend/bg-items/dots.json"];
	var loader = new PIXI.AssetLoader(assetsToLoad, false); // create a new loader

	function onAssetsLoadedDotsHave() {
		initAssets(dotsList, dots);
		initAssets(popcornsList, popcorns);
		initAssets(snacksList, snacks);

		// start drawing
		//requestAnimFrame(draw);
		window.requestAnimationFrame(draw)
	};

	function onAssetsLoadedDotsNo() {
		initAssets(popcornsList, popcorns);
		initAssets(snacksList, snacks);

		// start drawing
		//requestAnimFrame(draw);
		window.requestAnimationFrame(draw)
	};

	if (haveDots == 1) {
		loader.onComplete = onAssetsLoadedDotsHave; // use callback
	} else {
		loader.onComplete = onAssetsLoadedDotsNo; // use callback
	}

	loader.load(); // begin load

	// Create an new instance of a pixi stage
	var stage = new PIXI.Stage();

	// Create a renderer instance and add the renderer view elem at the DOM
	var renderer;
	if(_stenikMain && _stenikMain.isIE()) {
		renderer = new PIXI.CanvasRenderer(windowWidth, windowHeight, { transparent: true });
	} else {
		renderer = PIXI.autoDetectRenderer(windowWidth, windowHeight, { transparent: true });
	}

	document.body.appendChild(renderer.view);
	renderer.view.className = renderer.view.className + " animatedBg";
	window.onresize = function() {
		canvasSizeCheck();

		renderer.resize(windowWidth, windowHeight);
		renderer.view.style.width  = windowWidth + "px";
		renderer.view.style.height = windowHeight + "px";
		requestRedraw();
	}

	// Create an empty container and use it as a camera
	var cameraContainer = new PIXI.DisplayObjectContainer();
	cameraContainer.position.x = 0;
	cameraContainer.position.y = 0;
	stage.addChild(cameraContainer);

	// Init the assets and fill the holders with the PIXI objs
	var offsetX = windowWidth/2;
	var offsetY = 0;
	function initAssets(listArray, holder) {
		for (var i = 0; i < listArray.length; i++) {
			// create a texture from its name
			var texture     = PIXI.Texture.fromFrame(listArray[i][2]);
			var item        = new PIXI.Sprite(texture);
			item.initedY    = offsetY + listArray[i][0];
			item.position.y = offsetY + listArray[i][0];
			item.position.x = offsetX + listArray[i][1];
			item.anchor.x   = 0.5;
			item.anchor.y   = 0.5;
			holder.push(item);
			cameraContainer.addChild(item);
		}
	}

	// Drawing...
	var needToDraw = true;
	var i = 0;
	function draw() {
		if(needToDraw) {
			renderer.render(stage);	// render the stage
			needToDraw = false;
		}
	}
	function requestRedraw() {
		needToDraw = true;
		//requestAnimFrame(draw);
		window.requestAnimationFrame(draw)
	}

	var maxY = 300,
		minY = -200;
	function boundariesCheck() {
		const tween = this;
		if(tween._targets) {
			if(typeof tween._targets[0] !== 'undefined' && tween._targets[0].y > tween._targets[0].initedY + maxY) {
				gsap.to(tween._targets, { duration:0.6, y: tween._targets.initedY + maxY, ease: Power1.easeOut, onUpdate: requestRedraw });
			} else if(typeof tween._targets[i] !== 'undefined' && tween._targets[i].y < tween._targets[i].initedY + minY) {
				gsap.to(tween._targets, { duration:0.6, y: tween._targets.initedY + minY, ease: Power1.easeOut, onUpdate: requestRedraw });
			} else {
				requestRedraw();
			}
		}
	}
	var currentScrollPosition = pageYOffset || (document.documentElement.clientHeight ? document.documentElement.scrollTop : document.body.scrollTop) || jQuery(window).scrollTop();
	var lastScrollPosition = currentScrollPosition;

	var movingDown = false;


	function scrollAnimCall(e) {
		currentScrollPosition = pageYOffset || (document.documentElement.clientHeight ? document.documentElement.scrollTop : document.body.scrollTop) || jQuery(window).scrollTop();

		if(currentScrollPosition > lastScrollPosition) {
			console.log('down', movingDown)
			if(!movingDown) {
				gsap.to(popcorns, { duration:1.2, y: "+=10", rotation: "+=0.15", ease: Power1.easeOut,
					onUpdate: boundariesCheck,
					onUpdateParams:["{self}"]
				});
				gsap.to(snacks, { duration:1.2, y: "+=30", rotation: "+=0.1", ease: Power1.easeOut,
					onUpdate: boundariesCheck,
					onUpdateParams:["{self}"]
				});
				gsap.to(dots, { duration:1.2, y: "-=10", ease: Power1.easeOut,
					onUpdate: boundariesCheck,
					onUpdateParams:["{self}"]
				});
			}
		} else if(currentScrollPosition < lastScrollPosition) {
			// console.log('up')
			gsap.to(popcorns, { duration:1.2, y: "-=10", rotation: "-=0.15", ease: Power1.easeOut,
				onUpdate: boundariesCheck,
				onUpdateParams:["{self}"]
			});
			gsap.to(snacks, { duration:1.2, y: "-=30", rotation: "-=0.1", ease: Power1.easeOut,
				onUpdate: boundariesCheck,
				onUpdateParams:["{self}"]
			});
			gsap.to(dots, { duration:1.2, y: "+=10", ease: Power1.easeOut,
				onUpdate: boundariesCheck,
				onUpdateParams:["{self}"]
			});
		}

		lastScrollPosition = currentScrollPosition;
	}

	if(!isIE8 && !_stenikMain.isMobile() && !hasBranding) {
		window.onscroll = function() {
			//alert();
			scrollAnimCall();
		};
		
	}
})();
