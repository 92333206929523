import $ from 'jquery'

// create global $ and jQuery variables
require('gsap');
require('./js/js-bottom/04_jquery.ie.placeholder')
require('./js/js-bottom/05_colorbox-stenik-modification.min')
require('./js/js-bottom/06_jquery.dotdotdot.min')
require('./js/js-bottom/07_owl.carousel')
require('./js/js-bottom/08_tinynav.min')
require('./js/js-bottom/09_stenikMain')
require('./js/js-bottom/10_stenikRating')
require('./js/js-bottom/11_stenikSelect')
require('./js/js-bottom/12_sly.scroller')
require('./js/js-bottom/13_jquery.datetimepicker')
require('./js/js-bottom/14_stenikFilter')
require('./js/js-bottom/15_stenikTimer')
require('./js/js-bottom/16_pixi.min')
require('./js/js-bottom/17_stenik.animatedbg')
require('./js/js-bottom/18_jquery.smartzoom')
require('./js/js-bottom/from_base.js')

global.$ = global.jQuery = $;
$.fn.andSelf = function() {
    return this.addBack.apply(this, arguments);
}
$(".movieBox[data-responsive-href]").on('click', function() {
    window.location = $(this).data('responsive-href')
})