require('./05_colorbox-stenik-modification.min')
import { gsap, Back } from "gsap";
const YT = require('yt-player')

function contactUsPopUp() {
    jQuery.ajax({
        url: window.routes.contact_form,
        type: "GET"
    }).done(function(data){
        jQuery.colorbox({
            fixed: true,
            top: false,
            speed: false,
            width: '60%',
            innerWidth: '100%',
            maxHeight: false,
            transition: null,
            stenikAnimations: false,
            html: data
        })
    });
}

function loginPopUp() {
    jQuery.ajax({
        url: window.routes.loginPath,
        type: "GET"
    }).done(function(data){
        jQuery.colorbox({
            fixed: true,
            top: false,
            maxHeight: false,
            transition: null,
            stenikAnimations: true,
            html: data
        });
    });
}

/* PRINT ERRORS
--------------------------------------------------------------- */
function printErrors(errors, formName) {
    for (var key in errors) {
        if (Object.prototype.toString.call(errors[key]) === '[object Object]') {
            printErrors(errors[key], formName + key + '_');
        } else {
            $(formName + key).parents().eq(0).addClass('error').append('<div class="center red">'+ errors[key]  +'</div>');
        }
    }
}

window.addEventListener('DOMContentLoaded', (event) => {
    console.log(jQuery)
    /* Banner click handle
    --------------------------------------------------- */
    $('div.banner').on('click', function(e){
        e.preventDefault();
        e.stopPropagation();
        var banner = $(this).data('banner');
        var url = $(this).data('href');
        var target = $(this).data('target');
        if (banner != null) {
            $.ajax({
                url: window.routes.bannerClick,
                type: 'POST',
                dataType: 'json',
                data: {'banner': banner},
            })
            .done(function(data) {
                if (data.success) {
                    if (url) {
                        var a = document.createElement('a');
                        a.href = url;
                        a.target = target;
                        document.body.appendChild(a);
                        a.click();
                    };
                };
            });
        };
    });

    /* Banner click handle
    --------------------------------------------------- */
    $('a.disable').on('click', function(e){
        e.preventDefault();
        e.stopPropagation();
    })

    /* Add to (remove from) favourites
    --------------------------------------------------- */
    var favBtnSelector = '.favourites';
    if($(favBtnSelector).length > 0) {
        var user = window.username;
        var $favRequest;
        $(document).on('click', favBtnSelector, function(e) {
            e.preventDefault();
            e.stopPropagation();
            var $fav = $(this);
            if (user != '' && $fav.attr('href').length) {
                if($favRequest && $favRequest.readyState != 4) {
                    $favRequest.abort();
                }
                $favRequest = $.ajax({
                    url:  $fav.attr('href'),
                    type: 'GET'
                }).done(function(data){
                    if (data.removed) {
                        $fav.find('.txt').removeClass('contentAfter').addClass('contentBefore');
                        if($fav.hasClass('removeMovieBox')) {
                            $fav.parents('.movieBox').eq(0).remove();
                        }
                    } else {
                        $fav.find('.txt').removeClass('contentBefore').addClass('contentAfter');
                    }
                    $.colorbox({
                        fixed: true,
                        top: true,
                        transition: null,
                        stenikAnimations: true,
                        html: '<div class="popup">'+data.message+'</div>'
                    });
                });
            } else {
                loginPopUp();
            }
        });
    }
    /* Contact form */
    $(document).on('click', '.contactPopupLink', function(e){
        e.preventDefault();
        contactUsPopUp();
    });
    /* Login pop-ups
    --------------------------------------------------- */
    if(typeof window.doLogin !== 'undefined')
    {
        loginPopUp();
    }

    $(document).on('click', '.loginPopupLink', function(e){
        e.preventDefault();
        loginPopUp();
    });
    $(document).on('click', '.forgottenPasswordPopupLink', function(e){
        e.preventDefault();
        $.colorbox({
            fixed: true,
            top: true,
            transition: null,
            stenikAnimations: true,
            href: window.routes.fos_user_resetting_request
        });
    })
    $(document).on('click', '.registerPopupLink', function(e){
        e.preventDefault();

        $.colorbox({
            top: false,
            transition: null,
            stenikAnimations: true,
            overlayClose: false,
            escKey: false,
            href: window.routes.register
        });
    });

    /* Movies rating
    --------------------------------------------------- */
    $('.rating').find('input').stenikRating({
        handles: true,
        onSelect: function(data) {
            var $input = $(this).parents('.stenikRating').eq(0).find('input');
            $.ajax({
                url: window.routes.movie_rating,
                data: { 'movie': $input.data('movie'), 'rating': data},
                method: 'POST',
                dataType: 'JSON'
            }).done(function(data){
                if (data.success == false) {
                    if (data.message == 'login') {
                        loginPopUp();
                    }
                } else {
                    $input.stenikRating('setValue', data.rating);
                }

                $.colorbox({
                    fixed: true,
                    top: false,
                    transition: null,
                    stenikAnimations: true,
                    html: "<div class=\"popup\">" + data.message + "</div>"
                });
            }).fail(function(jqXHR, textStatus, errorThrown) {
                $.colorbox({
                    fixed: true,
                    top: false,
                    transition: null,
                    stenikAnimations: true,
                    html: "<div class=\"popup\"><strong class=\"red\">"+window.unexpected_error+"</strong></div>"
                });
            });
        }
    });


    /* Newsletter signup ajax
    --------------------------------------------------- */
    var $newsletterInput  = $('#newsletter_email'),
        $newsletterButton = $('#newsletter_button'),
        $newsletterRequest;
    var $newsletterGdprInput = $('#newsletter_gdprCheckNewsletter');
    $newsletterButton.on('click', function(e){
        e.preventDefault();
        e.stopPropagation();
        if($newsletterRequest && $newsletterRequest.readyState != 4) {
            $newsletterRequest.abort();
        }
        $newsletterRequest = $.ajax({
            url: window.routes.newsletter,
            type: 'post',
            data: { email: $newsletterInput.val(), gdprChecked: $newsletterGdprInput.is(":checked") },
            dataType: 'json'
        }).done(function(data){
            $newsletterInput.val('');
            var msg = data.html;
            if(data.success) {
                msg = '<div class="message success noMarginB">'+msg+'</div>';
            } else {
                msg = '<div class="message error noMarginB">'+msg+'</div>';
            }

            $.colorbox({
                fixed: true,
                top: true,
                transition: null,
                stenikAnimations: true,
                html:'<div class="popup">'+msg+'</div>'
            });
        });
    });
});

window.addEventListener('DOMContentLoaded', (event) => {
    /* Youtube player init
    ------------------------------------------------------- */
    // Asynchronously Youtube IFrame Player API load
    var tag = document.createElement('script');
    tag.src = "//www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    // Player init
    var $body = $('body');
    window.onYouTubeIframeAPIReady = function() {
        var $videoBox     = jQuery('#video_box'),
            $playerHolder  = jQuery('#player_holder'),
            $videoWrapper = $playerHolder.find('.videoWrapper'),
            $videoClose   = $playerHolder.find('.videoClose'),
            $videoHandles = jQuery('[data-video-id]');

        var $videoHandle,
            $myPlayer,
            myVideoId,
            videoClass = null;

            console.log($videoHandles)
        // Show
        $videoHandles.on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $videoHandle = jQuery(this);
            myVideoId    = $videoHandle.data('video-id');
            if($videoHandle.data('videoClass')) {
                videoClass = $videoHandle.data('videoClass');
                $videoBox.addClass(videoClass);
            }

            $body.addClass('videoActive').trigger('stenikPlayerActive');
            console.log($playerHolder)
            gsap.fromTo($playerHolder, {y: -100, opacity: 0}, { y: 0, opacity: 1, delay: 0.2, onComplete: function() {
                youtubeInit();
            } });
        });

        // Hide
        $videoClose.on('click', function() {
            gsap.to($playerHolder, { delay: 0.3, y: -100, opacity: 0, ease:Back.easeIn, onComplete: function() {
                $body.removeClass('videoActive').trigger('stenikPlayerInactive');
                if(videoClass) {
                    $videoBox.removeClass(videoClass);
                    videoClass = null;
                }
                $videoWrapper.empty();
            } });
        });
        function youtubeInit() {
            // Clear the previous player and add a new placeholder for the iframe
            $videoWrapper.empty().append('<div class="player" id="youtube_player"></div>');
            $myPlayer = new YT('#youtube_player', { 'autoplay': 1, rel: 0 });
            $myPlayer.load(myVideoId)
            // $myPlayer = $myPlayer.Player('youtube_player', {
            //     videoId: myVideoId,
            //     playerVars: { 'autoplay': 1, rel: 0 }
            // });
        }
    }
})